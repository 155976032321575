import '@assets/styles/pages/app-design-page.css'
import '@assets/styles/utils/chrome-bug.css'
import '@assets/styles/components/custom.css'
import '@assets/styles/main.css'
import '@assets/styles/components/product-image-gallery.css'
import '@assets/styles/components/new-product-image-gallery.css'
import '@assets/styles/components/rangeslider.css'
import '@assets/styles/components/table.css'

import { Head } from '@components/common'
import '@components/dynamic-loader/index.scss'
import { ManagedUIContext } from '@components/ui/context'
import 'keen-slider/keen-slider.min.css'
import { useRouter } from 'next/router'
import 'nprogress/nprogress.css'
import 'pure-react-carousel/dist/react-carousel.es.css'
import 'rc-color-picker/assets/index.css'
import { FC, PropsWithChildren, useEffect } from 'react'
import 'react-step-progress-bar/styles.css'
import { Provider as SessionProvider } from 'next-auth/client'
import Cookie from 'js-cookie'
import { GrowthBook } from '@growthbook/growthbook'
import { GrowthBookProvider } from '@growthbook/growthbook-react'
import {
  COOKIE_FEATURES,
  COOKIE_IRC_CLICK_ID,
  COOKIE_VISITOR_ID,
} from '@utils/constants'
import { trackABExperiment } from '@utils/gtm'
import { HistoryProvider } from '@utils/hooks/historyProvider'
import { NEXT_PUBLIC_BIGCOMMERCE_CHANNEL_COUNTRY } from '@utils/client-side-config'

const Noop: FC<PropsWithChildren> = ({ children }) => <>{children}</>

const elbrusPaths = ['blog', 'community', 'recommends', 'sustainability']

function checkStringContains(str, contains = []) {
  const regexps = contains.map((pattern) => new RegExp(pattern))
  for (const regexp of regexps) {
    if (regexp.test(str)) {
      return true
    }
  }

  return false
}

// Create a GrowthBook instance
const growthBook = new GrowthBook({
  trackingCallback: (experiment, result) => {
    // Only experiment is going here, not normal feature flag
    trackABExperiment(experiment.key, result.variationId)
  },
})

export default function MyApp({ Component, pageProps }) {
  const Layout = (Component as any).Layout || Noop
  const router = useRouter()
  const { asPath } = router

  useEffect(() => {
    if (!router.isReady) {
      return
    }
    const query = router.query
    const { irclickid } = query

    if (irclickid && !Cookie.get(COOKIE_IRC_CLICK_ID)) {
      Cookie.set(COOKIE_IRC_CLICK_ID, irclickid)
    }

    // TODO: Get all the ab_ flag and store it into cookie
  }, [router.isReady])

  useEffect(() => {
    if (checkStringContains(asPath, elbrusPaths)) {
      document.body.classList?.add('elbrus-headless')
    }
  }, [asPath])

  // Refresh features and targeting attributes on navigation
  useEffect(() => {
    growthBook.setAttributes({
      id: Cookie.get(COOKIE_VISITOR_ID),
      country: NEXT_PUBLIC_BIGCOMMERCE_CHANNEL_COUNTRY,
    })
    growthBook.setFeatures(JSON.parse(Cookie.get(COOKIE_FEATURES) || '{}'))
  }, [asPath]) // Should update growthBook on every path to refresh it from cookie value due to this component is mounted only one time per SPA

  return (
    <>
      <Head asPath={asPath} />
      <GrowthBookProvider growthbook={growthBook}>
        <SessionProvider session={pageProps.session}>
          <HistoryProvider>
            <ManagedUIContext>
              <Layout pageProps={pageProps}>
                <Component {...pageProps} />
              </Layout>
            </ManagedUIContext>
          </HistoryProvider>
        </SessionProvider>
      </GrowthBookProvider>
    </>
  )
}
